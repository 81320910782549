/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link'

const navigations = [
    { name: 'About Us', href: '/about-us', aria_label: 'Learn more about us' },
    //{ name: 'Rapide Club', href: '/rapide-club', aria_label: 'Check out rapide club benefits' },
    { name: 'Be A Partner', href: '/be-a-fleet-partner', aria_label: 'Be part of a rapidly growing P15 Billion Industry!' },
    { name: 'Franchise Us', href: '/franchise-us', aria_label: 'Be part of a rapidly growing P15 Billion Industry!' },
    { name: 'Contact Us', href: '/contact-us', aria_label: 'Our friendly  team will be happy to answer questions or inquiries' },
]

const Footer = () => {
    return (
        <footer className="relative bottom-0 bg-rapide-black overflow-hidden z-10 border-t border-gray-400">
            <div className="px-4">
                <div className="pt-[60px] pb-10">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-1/2 lg:w-5/12 2xl:w-1/3 px-4">
                            <div className="mb-10">
                                <Image
                                    className='mx-auto'
                                    alt={'rapide-logo'}
                                    src={`/assets/images/Rapide-Yellow-Logo_205.webp`}
                                    width={'205'}
                                    height={'79'}
                                />
                                <p className="mt-4 text-white text-center text-lg">CASA-quality services at affordable prices.</p>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/3 md:w-1/2 lg:w-2/12 2xl:w-2/12 px-4">
                            <div className="mb-6">
                                <h1 className=" font-bold text-lg sm:text-xl lg:text-lg xl:text-xl text-white mb-4 lg:text-left text-center">Useful Links</h1>
                                <ul className="space-y-2 lg:text-left text-center">
                                    {navigations.map((nav, i) => {
                                        return (
                                            <li key={i} className="w-full">
                                                <Link aria-label={nav.aria_label} className="text-base inline-block text-rapide-yellow hover:text-rapide-red" href={nav.href}>
                                                    {nav.name}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/3 md:w-1/2 lg:w-2/12 px-4">
                            <div className="mb-6">
                                <h1 className=" font-bold text-lg sm:text-xl lg:text-lg xl:text-xl text-white mb-4 lg:text-left text-center">Engage</h1>
                                <ul className="space-y-2 lg:text-left text-center">
                                    <li>
                                        <Link className="text-base inline-block text-rapide-yellow hover:text-rapide-red" href="/terms-of-service">
                                            Terms of Service
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="text-base inline-block text-rapide-yellow hover:text-rapide-red" href="/privacy-policy">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/3 md:w-1/2 lg:w-3/12 px-4">
                            <div className="">
                                <h1 className=" font-bold text-lg sm:text-xl lg:text-lg xl:text-xl text-white mb-4 lg:text-left text-center">Support &amp; Help</h1>
                                <ul className="space-y-6">
                                    <li className="">
                                        <a className="lg:justify-start justify-center align-middle items-center text-base flex text-rapide-yellow hover:text-rapide-red" target="_blank" rel="noopener noreferrer" href="tel:+639660619979">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                            </svg>
                                            <span className="inline-block ml-2 text-rapide-yellow hover:text-rapide-red">0966 061 9979 (Globe)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="lg:justify-start justify-center align-middle items-center text-base flex text-rapide-yellow hover:text-rapide-red" target="_blank" rel="noopener noreferrer" href="tel:+639192694103">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                            </svg>
                                            <span className="inline-block ml-2 text-rapide-yellow hover:text-rapide-red">0919 269 4103 (Smart)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <div className="lg:justify-start justify-center lg:items-start items-center lg:align-baseline align-middle flex flex-col text-rapide-yellow hover:text-rapide-red">
                                            <span className="inline-block ml-2 text-rapide-yellow hover:text-rapide-red">Viber</span>
                                            <div className='relative block'>
                                                <Image
                                                    src={`/assets/images/rapide_viber.webp`}
                                                    alt='rapide viber'
                                                    width={200}
                                                    height={200}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="lg:text-left text-center text-white">Main Office:</p>
                                        <p className="lg:text-left text-center text-white">Monday to Sunday</p>
                                        <p className="lg:text-left text-center text-white">8:30 A.M - 5:30 P.M</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-6 border-t border-stroke">
                    <div className="flex flex-wrap -mx-4 items-center">
                        <div className="w-full md:w-1/3 lg:w-5/12 px-4">
                            <ul className=" flex items-center justify-center md:justify-start mb-8 -mx-2 ">
                                <li className="px-2">
                                    <a href="https://www.facebook.com/RapideAutoServicePH" target="_blank" rel="nofollow noreferrer" name="facebook" aria-label="facebook" className=" w-9 h-9 flex items-center justify-center rounded-full border border-[#dbdbdb] text-rapide-yellow hover:border-primary hover:text-white hover:bg-primary ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="px-2">
                                    <a href="https://twitter.com/rapideautoph" target="_blank" rel="nofollow noreferrer" name="twitter" aria-label="twitter" className=" w-9 h-9 flex items-center justify-center rounded-full border border-[#dbdbdb] text-rapide-yellow hover:border-primary hover:text-white hover:bg-primary ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="px-2">
                                    <a href="https://www.instagram.com/rapideautoph/" target="_blank" rel="nofollow noreferrer" name="instagram" aria-label="instagram" className=" w-9 h-9 flex items-center justify-center rounded-full border border-[#dbdbdb] text-rapide-yellow hover:border-primary hover:text-white hover:bg-primary ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="px-2">
                                    <a href="https://www.youtube.com/channel/UCz6YcqrqFziHr0Tt8r1Hg3Q" target="_blank" rel="nofollow noreferrer" name="youtube" aria-label="youtube" className=" w-9 h-9 flex items-center justify-center rounded-full border border-[#dbdbdb] text-rapide-yellow hover:border-primary hover:text-white hover:bg-primary ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="px-2">
                                    <a href="https://invite.viber.com/?g2=AQAl6zvbEBN0HE9ujGs%2FeSK47n%2ByU3g81vYZ%2FHJx5RR3cGXI0cAA5i19f8Z4LLg2" target="_blank" rel="nofollow noreferrer" name="viber" aria-label="viber" className=" w-9 h-9 flex items-center justify-center rounded-full border border-[#dbdbdb] text-rapide-yellow hover:border-primary hover:text-white hover:bg-primary ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-label="Viber" width="32" height="32" viewBox="0 0 512 512">
                                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="10" d="M269 186a30 30 0 0 1 31 31m-38-58a64 64 0 0 1 64 67m-73-93a97 97 0 0 1 99 104"/><path d="M288 274q10-13 24-4l36 27q8 10-7 28t-28 15q-53-12-102-60t-61-104q0-20 25-34 13-9 22 5l25 35q6 12-7 22c-39 15 51 112 73 70zM95 232c0 78 14 95 36 118 7 7 32 19 38 19v69c0 4 4 7 8 3l53-63 26 1c144 0 161-56 161-147S400 85 256 85 95 141 95 232zm-30 0c0-126 55-177 191-177s191 51 191 177-55 177-191 177c-10 0-18 0-32-2l-38 43c-7 8-28 11-28-13v-42c-6 0-20-6-39-18-19-13-54-44-54-145z"/>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full md:w-2/3 lg:w-7/12 px-4">
                            <div className="mb-8">
                                <p className="text-base text-rapide-yellow text-center md:text-right">© Copyright 2022 | Rapide.ph</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer
